import ImgAndre from './img/andre.jpg'
import './css/home.css'

function Header () {
    return (

        <header>

            <div className='box'>
                <div className="andre">
                        <img src={ImgAndre} className="logo" alt="logo" />
                        <h1>André Bastos</h1>
                    </div>
                </div>

                <div className="isbrasil">
                    <a href="https://www.isbrasil.info" target="_blank" rel="noopener noreferrer">Hosedagem de Site</a>
                </div>

        </header>
        

    )   
}

export default Header